import React, { Component } from 'react';
import Loading from './Loading';
import AttachedImages from './AttachedImages';
import textPressPhotos from './../assets/text-press-photos.png';
import textDownloads from './../assets/text-downloads.png';
import textContact from './../assets/text-contact.png';
//import i18n from '../i18n';

const API = 'https://admin.longtalljefferson.com/wp-json/wp/v2/';
const QUERY = 'one_pager/234/?_embed&filter[orderby]=menu_order&order=asc';

class Epk extends Component {
    constructor(props) {
        super(props);
        this.state = { dataSet: [], isLoading: true }
    }
    
    componentDidMount() {
        let addLang = "";
        fetch(API+QUERY+addLang)
            .then(response => {
                if  (response.ok) {
                    return response.json();
                } else {
                    throw new Error('Data unavailbale.');
                }
            })
        .then(response => { this.setState({ dataSet: response, isLoading: false }) })
        .catch(error => this.setState({ error, isLoading: false }));
    }

    render() {
        if (this.state.isLoading) {
            return <Loading />;
        }
        let content = this.state.dataSet.content.rendered;
        let albumEmbed = this.state.dataSet.acf.album_embed;
        let attachedImages = this.state.dataSet.acf_photo_gallery;
        let downloadLinks = this.state.dataSet.acf.download_links;
        let contact = this.state.dataSet.acf.contact;
        return (
            <div>
                <div className="epk">
                    <div className="blockFull">
                       <div className="blockText">
                            {/*<div className="anchor" dangerouslySetInnerHTML={{__html: title}}></div>*/}
                            <div className="album padLow" dangerouslySetInnerHTML={{__html: albumEmbed}}></div>
                            <div className="about padLow" dangerouslySetInnerHTML={{__html: content}}></div>
                            <div className="anchor"><img src={textPressPhotos} className="title" alt="Press Photos"/></div>
                            <AttachedImages data={attachedImages} />
                            <div className="anchor"><img src={textDownloads} className="title" alt="Downloads"/></div>
                            <div className="downloadLinks padLow" dangerouslySetInnerHTML={{__html: downloadLinks}}></div>
                            <div className="anchor"><img src={textContact} className="title" alt="Contact"/></div>
                            <div className="contact padLow" dangerouslySetInnerHTML={{__html: contact}}></div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Epk;