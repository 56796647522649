import React, { Component } from 'react';
import { BrowserRouter, Routes, Route, Link } from 'react-router-dom';
import CookieConsent from "react-cookie-consent";
import HomePage from '../pages/HomePage';
import PrivacyPage from '../pages/PrivacyPage';
import ImprintPage from '../pages/ImprintPage';
import i18n from '../i18n';
import { HelmetProvider } from 'react-helmet-async';

class Router extends Component {

    render() {
        return (
            <BrowserRouter>
                <HelmetProvider>
                    
                    <HomePage/>
                </HelmetProvider>
            </BrowserRouter>
        );
    }
}

export default Router;